###
  Extracted from Backbone-Rails gem  (https://github.com/codebrew/backbone-rails)
  and improved to allow for the sending of USERNAME & API KEY credentials

    -   Appends a paramRoot onto CREATE, UPDATE and PATCH requests to match Rails style requests

    Usage:
    - Load this file after loading Backbone
    - To append API credentials ensure window.__mb.credentials.email & *.api_key are set
      prior to load.
    - In your Backbone.model set the attribute:
      paramRoot: 'rails_model'
###

define [
  'underscore',
  'backbone'
], (_, Backbone) ->

  Backbone._sync = Backbone.sync
  Backbone.sync = (method, model, options) ->

    # Serialize data, optionally using paramRoot
    if not options.data? and model and (method is "create" or method is "update" or method is "patch")
      options.contentType = "application/json"
      data = JSON.stringify(options.attrs or model.toJSON(options))
      if model.paramRoot
        data = {}
        data[model.paramRoot] = model.toJSON(options)
      else
        data = model.toJSON()
      options.data = JSON.stringify(data)
    Backbone._sync method, model, options

