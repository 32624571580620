define [
  'jquery'
  'jquery-ujs'
  'underscore'
  'bootstrap3'
  'modules/btn-clipboard'
], ($,{}, _) ->
  window.$ = $
  window.jQuery = $
  window.jquery = $
  window._ = _

  return $
