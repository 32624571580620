define ['common',
        'backbone',
        'underscore'
], ($, Backbone, _) ->

  Backbone.Model.prototype._toJSON = Backbone.Model.prototype.toJSON

  Backbone.Model.prototype.toJSON = (options) ->
    try
      attrs = _.clone(@attributes)
      data = {}
      if @relations
        _.each @relations, (rel, key) ->
          key_name = "#{key}_attributes"
          if _.has(attrs, key)
            attrs[key_name] = attrs[key].toJSON()
            delete attrs[key]
      attrs

    catch e
      console.error "Error: %O", e
      return @_toJSON()

