import $ from 'jquery'
import ClipboardJS from 'clipboard'

const listenForClipboardEvent = (className) => {
  new ClipboardJS(className)
  $(".btn-clipboard").removeClass('disabled')
}

window.globalListenForClipboardEvent = listenForClipboardEvent

$(document).ready(function() {
  if ($(".btn-clipboard").length > 0) {
    listenForClipboardEvent(".btn-clipboard")
  }
})

