define [
  'common',
  'underscore',
  'backbone',
  'backbone.marionette',
  'backbone-rails.sync',
  'backbone.stickit',
  'backbone-validation',
  'common/backbone.rails_relations'
], ($, _, Backbone, Marionette, CommonViews) ->

  App = new Backbone.Marionette.Application()

#  window._App = App

  App.startSubApp = (appName, args) =>
    currentApp = if appName then App.module(appName) else null
    return  if App.currentApp is currentApp
    App.currentApp.stop()  if App.currentApp
    App.currentApp = currentApp
    currentApp.start(args)  if currentApp
    return

  App._globals = {}
  App._globals.countries = JSON.parse( $("#countries-config").attr('content') )


  App.addInitializer () ->
    App.addRegions(
      flash: '#mb-app-flash'
      main: '#mb-app'
    )

  App.on 'start', ->
    require ['apps/common/error_handling',
             'apps/common/views',
             'apps/payments/payments_app',
             'apps/shipments/shipments_app'], ({}, CommonViews, PaymentsApp, ShipmentsApp) =>

      App.flash.show(new CommonViews.FloatingAlert(message: 'Please wait..'))
      App.module('PaymentsApp').start()
      App.module('ShipmentsApp').start()

      @listenToOnce(App, 'subApp:ready', ->
        App.flash.empty()
      )

  # Override default Backbone.Validation callbacks
  _.extend Backbone.Validation.callbacks,
    valid: (view, attr, selector) ->
      $el = view.$("[name=" + attr + "]")
      $group = $el.parent()
      $group.removeClass "has-error"
      $group.find(".help-block").html("").addClass "hidden"
      return

    invalid: (view, attr, error, selector) ->
      $el = view.$("[name=" + attr + "]")
      $group = $el.parent()
      $group.addClass "has-error"
      $group.find(".help-block").html(error).removeClass "hidden"
      return
  # Override default Backbone.Validations messages
  _.extend Backbone.Validation.messages,
      required: 'Required'


  App.start()

  return App
